import React, { useEffect } from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import { Link } from 'react-scroll';
import AddImage1 from '../../assets/img/add/279x396.jpg';
import AddImage2 from '../../assets/img/add/174x293.jpg';
import AddImage3 from '../../assets/img/add/116x130.jpg';
import AddImage4 from '../../assets/img/add/174x198.jpg';

export default function Project() {
  useEffect(() => {
    document.addEventListener('scroll', trackScrolling);

    return () => {
      document.removeEventListener('scroll', trackScrolling);
    }
  })

  function isBottom(el) {
    return el?.getBoundingClientRect().bottom - 200 <= window.innerHeight;
  }

  const trackScrolling = () => {
    const wrappedElement = document.getElementById('projectImagesContainer');
    if (isBottom(wrappedElement)) {
      console.log('header bottom reached');
      document.getElementById('projectImages').classList.add('animate')
      document.removeEventListener('scroll', trackScrolling);
    }
  };
  return (
    <Wrapper id="project">
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter" id="projectImagesContainer">
            <AddLeft>
              {/*<h4 className="font15 semiBold">A few words about company</h4>*/}
              <h2 className="font40 extraBold">О проекте</h2>
              <p className="font14">
                Seascape — жилой комплекс из четырех зданий в Rashid Yachts & Marina. Из новой гавани открывается беспрепятственный доступ к центру Дубая, а внутри разместилось 430 причалов для яхт длиной до 100 метров, где можно оставить яхту на одну ночь, сезон или на целый год. Из комплекса открывается захватывающий вид на пристань для яхт и голубой горизонт Персидского залива. Современная архитектура зданий отражает прибрежное расположение комплекса — геометрия зданий напоминает коралловые рифы, а белые и серо-бежевые фасады отсылают к элегантным яхтам на побережье.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                <div style={{ width: "190px" }}>
                  <Link to="contact" smooth={true}><FullButton title="Оставить заявку" /></Link>
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <Link to="contact" smooth={true}>
                    <FullButton title="Обратная связь" border />
                  </Link>
                </div>
              </ButtonsRow>
            </AddLeft>
            <AddRight id="projectImages">
              <AddRightInner>
                <div className="flexNullCenter">
                  <AddImgWrapp1 className="flexCenter">
                    <img src={AddImage1} alt="office" />
                  </AddImgWrapp1>
                  <AddImgWrapp2>
                    <img src={AddImage2} alt="office" />
                  </AddImgWrapp2>
                </div>
                <div className="flexNullCenter">
                  <AddImgWrapp3>
                    <img src={AddImage3} alt="office" />
                  </AddImgWrapp3>
                  <AddImgWrapp4>
                    <img src={AddImage4} alt="office" />
                  </AddImgWrapp4>
                </div>
              </AddRightInner>
            </AddRight>
          </Advertising>
        </div>
      </div>
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Почему Seascape?</h1>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Образ жизни"
                text={
                  <span>
                    Роскошные таунхаусы и квартиры в Seascape станут идеальным местом для размеренного образа жизни на морском побережье. Здесь вас ждут приятные прогулки в районе пристани, вкусные блюда, комфортный отдых у большого бассейна и расслабленная атмосфера в прибрежных ресторанах и кафе.
                    <br/><br/>Поддерживать прекрасную физическую форму можно, не покидая территории комплекса. К вашим услугам большой тренажерный зал с профессиональным оборудованием, а также различные спортивные площадки. Дети смогут веселиться в открытых игровых зонах и на водных аттракционах.
                    <br/><br/>В пешей доступности от нового ЖК находится набережная Rashid Yachts & Marina, на территории которой работают плавучие рестораны, кафе, лаунджи и пляжные бары.
                  </span>
                }
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Преимущества ЖК"
                text={
                  <>
                    В элитном жилом комплексе прекрасно сочетаются идеальная инфраструктура и удачное расположение в активно развивающемся прибрежном районе Дубая Mina Rashid. Большим плюсом ЖК является наличие на его территории и в непосредственной близости точек розничной торговли, плавучих ресторанов, развлекательных объектов. Жители комплекса смогут отдыхать в 6 взаимосвязанных парках, приватном пляжном клубе, посещать легендарный отель на воде Queen Elizabeth 2 и музей Sheikh Saeed Al Maktoum House, ранее бывший дворцом правителей Дубая.
                    <br/><br/>На пристани доступно 430 яхтенных причалов, которые подойдут для лодок длиной до 100 метров. В яхт-клубе круглосуточно и без выходных работает многоязычная профессиональная команда, оказывающая целый ряд услуг по обслуживанию лодок.
                  </>
                }
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Завершение строительства"
                text={
                  <span>
                    Строительство жилого комплекса началось в IV квартале 2022 года. Планируемая дата передачи объекта в эксплуатацию – IV квартал 2026 года. Параллельно со строительством идет глобальная реконструкция района Mina Rashid. После завершения строительных работ он станет одним из самых престижных районов Дубая для отдыха, а также одним из крупнейших в городе центром яхтинга.
                    <br/><br/>Проект ЖК разрабатывает известный девелопер Emaar Properties с большим опытом работы и прекрасной репутацией на рынке недвижимости Дубая. Это говорит о том, что, покупая квартиру или таунхаус в строящемся ЖК Seascape по цене застройщика, вы можете быть уверены, что получите готовое жилье строго в обозначенные сроки.
                  </span>
                }
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <BlogBox
                title="Недвижимость в Seascape для инвестиций"
                text={
                  <span>
                    Недвижимость в Seascape для инвестицийЖилье в Seascape станет идеальным объектом для инвестирования. Пока ЖК строится, инвесторы могут приобрести квартиры и таунхаусы с хорошей скидкой от девелопера. После передачи ЖК в эксплуатацию капитальная стоимость квартир возрастет, и владельцы смогут выгодно их перепродать.
                    <br/><br/>Для долгосрочного инвестирования подойдет вариант сдачи недвижимости в аренду. Прибрежный район расположен рядом с пляжами, набережной и крупнейшим в городе круизным терминалом, и эта выигрышная локация будет привлекать арендаторов. Владельцы арендной недвижимости в Дубае обычно получают доход в размере 8-10% годовых.
                    <br/><br/>Информацию о доходности квартир в новом ЖК и все детали об инвестициях в недвижимость от застройщика Emaar Properties в Дубае можно узнать у наших специалистов.
                  </span>
                }
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-12 flexCenter flex x100" style={{marginTop: 30}}>
              <Link to="contact" smooth={true}><FullButton title="Оставить заявку" /></Link>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
  #projectImages {
    display: none;
  }
  #projectImages.animate {
    display: block;
    animation-duration: 0.5s;
    animation-name: animate-pop;
    animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
  }

  @keyframes animate-pop {
    0% {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;

